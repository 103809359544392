<template>
  <base-layout>
    <ion-searchbar @click="filterbyLetter('all')" @ionChange="filter(search)" :value="search"
                   @ionInput="search = $event.target.value;"></ion-searchbar>

    <ion-content class="has-header has-subheader">
<div class="p-3" v-if="searchDone  && completeResults == 0">
        We're sorry, we didn't find a search result for "{{ search }}".
        Please try again.
      </div>
    
      <div class="row px-3 mb-2" style="background: #E30513;">      
        <div class="col p-2" >
        <div class="row p-2">
          <template  v-for="person in persons" :key="person.id">
            <div class="col px-2" style="text-align: center; color: #E30513; background:white; border-radius:25px" v-if="person.isLetter && selectedWord == person.letter"> <b> {{person.letter}} </b> </div>
            <div class="col px-2" style="text-align: center; color: white"  @click="filterbyLetter(person.letter)" v-if="person.isLetter && selectedWord != person.letter"> <b> {{person.letter}} </b> </div>
          </template>
        </div>
        </div>
      </div>
      
      <div class="container-fluid p-0 p-md-2">
        <div class="" v-if="selectedWord=='all'">
          <template v-for="person in filteredPersons" :key="person.id">
            <person-item :person="person" v-if="person" ></person-item>
          </template>
        </div>
        <div class="" v-if="selectedWord!='all'">
          <div class="col-12" style="width:100vw; padding-left:15px">
           <h3 class="my-0">{{selectedWord}}</h3>
          </div>
          <template v-for="person in filteredbyLetter" :key="person.id">
            <person-item :person="person" v-if="person" ></person-item>
          </template>
        </div>
      </div>

      <ion-infinite-scroll v-if="selectedWord=='all'"
          @ionInfinite="increaseLimit($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSearchbar, IonInfiniteScroll, IonInfiniteScrollContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import PersonItem from "@/components/PersonItem";

export default defineComponent({
  name: "Home",
  data() {
    return {
      persons: [],
      search: "",
      filtered: [],
      limit: 50,
      letters: {},
         searchDone: false,
      completeResults: 0,
      selectedWord: 'all',
      filteredbyLetter: [],
    };
  },
  components: {
    IonContent,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    PersonItem
  },
  methods: {
    ...mapActions("persons", ["getAllPersons"]),
    filterbyLetter(word){
      console.log("ckucj")
      this.selectedWord = word;
      let people =  this.persons;
      this.filteredbyLetter = people.filter(person => {
        if(!person.isLetter){
         return !person.isLetter && person.last_name.charAt(0) == word;
         }
      });
        

    },
    filter(term) {
      if (term.length > 0) {

        this.filtered = this.persons.filter(
            person => Object.keys(person).some(k => {
              return (person[k] && typeof person[k] == 'string' ? person[k].toLowerCase().includes(term.toLowerCase()) : '')
            })
        ).sort((a, b) => {
          return a.last_name.localeCompare(b.last_name);
        });
         this.searchDone = true;
        this.completeResults = this.filtered.length;
      } else {
        this.filtered = [];
         this.searchDone = false;
        this.completeResults = 0;
      }
    },
    increaseLimit(ev) {
      setTimeout(() => {
        this.limit += 50;
        console.log('Loaded data');
        ev.target.complete();

        if (this.persons.length <= this.limit) {
          ev.target.disabled = true;
        }
      }, 500);
    },

    groupList(list, selector) {
      let letters = [];
      let currentCharCode = 'A'.charCodeAt(0) - 100;
      let sortedList = [];

      function addLetter(code) {
        let letter = String.fromCharCode(code);
        sortedList.push({
          isLetter: true,
          letter: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().charCodeAt(0);
          let difference = itemCharCode - currentCharCode;
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });

      return sortedList;
    }

  },
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),

    filteredPersons: function () {
    let returnList = this.searchDone ? this.filtered : this.persons;
      return this.groupList(returnList.slice(0, this.limit), 'last_name');
    }
  },
  async created() {
    this.persons = (await this.getAllPersons()).sort((a, b) => {
      if (a.last_name) { //eine Person hatte keinen Vor- oder Nachnamen, ich habe ein if angegeben, um den undefined zu vermeiden
        return a.last_name.localeCompare(b.last_name);
      }
    });
    this.persons = this.groupList(this.persons, 'last_name');

  }
});
</script>
<style lang="scss">
</style>
